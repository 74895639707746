import getAxios from "services/axios-get";

export default {
  data() {
    return {
      addDealer: {
        sgcmId: "",
        ucarsId: "",
        dealerName: "",
        sgcmUrl: "",
      },
      isLoading: false,
      show: false,
    };
  },
  methods: {
    async doAddDealer() {
      const self = this;
      this.isLoading = true;
      try {
        getAxios(
          `${process.env.VUE_APP_ANALYTICS_URL}/api/v1/dealerListings?crud_type=insert&sgcm_id=${self.addDealer.sgcmId}&ucars_id=${self.addDealer.ucarsId}&name=${self.addDealer.dealerName}&sgcarmart_url=${self.addDealer.sgcmUrl}`,
          {},
          self.token
        ).then(res => {
          if (res.data) {
            self.show = false;
            this.isLoading = false;
            self.$emit("add");
            self.reset();
          }
        });
      } catch (e) {
        self.$emit("error", e);
        self.isLoading = false;
        console.error(e);
      }
    },
    async reset() {
      this.addDealer = {
        sgcmId: "",
        ucarsId: "",
        dealerName: "",
        sgcmUrl: "",
      };
    },
  },
};
