<template>
  <div class="dealer">
    <v-snackbar v-model="snackbar" :timeout="2000" top>
      {{ snackMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Confirmation
        </v-card-title>

        <v-card-text>
          Are you sure you want to delete this dealer:
          <b>{{ delDealer.dealerName }}</b
          >?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="doDelDealer()">
            Yes
          </v-btn>
          <v-btn color="primary" text @click="deleteDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="6" sm="6" md="3">
        <v-text-field
          @keyup.enter.native="searchType()"
          v-model="ucarsIdSearch"
          label="UCars ID"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          @keyup.enter.native="searchType()"
          v-model="dealerNameSearch"
          label="Dealer Name"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="2"
        :style="{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
        }"
      >
        <v-btn
          @click="searchType()"
          @keyup.enter.native="searchType()"
          large
          elevation
          color="primary"
        >
          Search
        </v-btn>
      </v-col>
    </v-row>

    <v-col class="d-flex" cols="3" sm="2">
      <v-select
        v-model="pagination.limit"
        :items="pageSizeList"
        item-value="value"
        item-text="text"
        label="Page Size"
        outlined
        @change="handlePageChange()"
      >
      </v-select>
      <!--#######################################################-->
      <!--#######################################################-->
      <!--#######################################################-->
      <!--#######################################################-->
      <!--#######################################################-->
      <!--#######################################################-->
      <AddSyncDealerDialog
        @add="add"
        @error="showSnack('Add Dealer Failed' + $event, 'error')"
      />
    </v-col>
    <v-data-table
      :headers="headers"
      :items="dealerList"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.sgcmId }}</td>
          <td>{{ row.item.ucarsId }}</td>
          <td>{{ row.item.dealerName }}</td>
          <td>
            <v-btn
              class="mx-2"
              v-if="row.item.status != 'POPULATING_LTA_DATA'"
              fab
              dark
              small
              color="red"
              @click="delButtonClick(row.item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
          <td>{{ row.item.sgcmUrl }}</td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
// import postAxios from "services/axios-post";
// import putAxios from "services/axios-put";
import getAxios from "services/axios-get";
// import deleteAxios from "services/axios-delete";

import AddSyncDealerDialog from "@/components/AddSyncDealerDialog";

Vue.use(VueAxios, axios);

export default {
  components: {
    AddSyncDealerDialog,
  },
  data() {
    return {
      crudType: "",
      sgcmId: "",
      ucarsId: "",
      dealerName: "",
      sgcmUrl: "",
      componentKey: 0,
      snackMessage: "",
      snackbar: false,
      color: "primary",
      pageCount: 0,
      pageSizeList: [10, 20, 50, 100],
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      delUsedCar: {
        id: "",
        name: "",
      },
      delDealer: {
        sgcmId: "",
        ucarsId: "",
        dealerName: "",
        sgcmUrl: "",
      },
      ucarsIdSearch: "",
      dealerNameSearch: "",
      total: 0,
      deleteDialog: false,
      token: "",
      headers: [
        { text: "SGCM_ID", value: "sgcmId" },
        { text: "UCars_ID", value: "ucarsId" },
        { text: "Dealer", value: "dealerName" },
        { text: "Delete" },
        { text: "sgcarmart_url", value: "sgcmUrl" },
      ],
      dealerList: [],
    };
  },
  mounted() {
    this.fetchDealerListing();
  },
  methods: {
    paginationChangeHandler() {
      this.fetchDealerListing();
    },
    delButtonClick(item) {
      this.delDealer.sgcmId = item.sgcmId;
      this.delDealer.ucarsId = item.ucarsId;
      this.delDealer.dealerName = item.dealerName;
      this.delDealer.sgcmUrl = item.sgcmUrl;
      this.deleteDialog = true;
    },
    doDelDealer() {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_ANALYTICS_URL}/api/v1/dealerListings?crud_type=delete&sgcm_id=${self.delDealer.sgcmId}&ucars_id=${self.delDealer.ucarsId}&name=${self.delDealer.dealerName}&sgcarmart_url=${self.delDealer.sgcmUrl}`,
        {},
        self.token
      ).then(res => {
        if (res.data) {
          self.deleteDialog = false;
          self.fetchDealerListing();
          self.showSnack("Delete Dealer Successfully", "success");
        }
      });
    },
    searchType() {
      console.log(this.dealerNameSearch);
      this.fetchDealerListing();
    },
    handlePageChange(newPage) {
      this.pagination.page = newPage;
      this.fetchDealerListing();
    },
    showSnack(msg, color) {
      this.snackMessage = msg;
      this.color = color;
      this.snackbar = true;
    },
    add() {
      this.fetchDealerListing();
      this.showSnack("Add Dealer Successfully", "success");
    },
    fetchDealerListing() {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_ANALYTICS_URL}/api/v1/dealerListings?crud_type=read&ucars_id=${self.ucarsIdSearch}&name=${self.dealerNameSearch}`,
        {},
        self.token
      ).then(res => {
        if (res.data) {
          self.dealerList = res.data.data;
          self.pagination.noOfPage = Math.ceil(
            self.dealerList.length / self.pagination.limit
          );
          // console.log(self.dealerList);
        }
      });
    },
  },
};
</script>
<style>
.plusButton {
  margin: 50px;
}
.status-but {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}
</style>
